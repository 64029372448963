.app-components {
	display: flex;
	gap: 10rem;
	max-width: 80%;
	margin-top: 5rem;
	padding: 0px 10px;
}

.left-section {
	position: sticky;
	top: 5rem;
	height: fit-content; /* Adjust to ensure the section covers its content */
	flex-shrink: 0; /* Prevent left section from shrinking */
}

.right-section {
	overflow-y: auto; /* Allow the content to scroll if it exceeds the height */
	display: flex;
	flex-direction: column;
	gap: 4rem;
	margin-left: 1rem;
	max-width: 48rem;
	flex: 1; /* Allow the right section to grow to fill available space */
	margin-bottom: 2rem;
}

@media (max-width: 1024px) {
	.app-components {
		flex-direction: column;
		gap: 3rem;
		max-width: 100%;
		margin-left: var(--layout-left-margin-mobile);
	}

	.left-section {
		position: relative; /* Remove sticky positioning for smaller screens */
		top: auto;
	}
	.right-section {
		margin-left: 0;
	}
}

.section-title {
	display: none;
}

@media (max-width: 1024px) {
	.section-title {
		display: block;
		margin: 2rem 0;
		color: var(--titles-font-color);
		font-weight: var(--sub-titles-font-weight);
		font-size: var(--sub-titles-font-size);
	}
}
