.copyright {
	margin: 1rem 0; /* Add margin at the top if needed */
	color: var(--text-color);
	font-size: 0.7rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.copyright p {
	padding: 0;
	margin: 0;
}
