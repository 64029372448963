body {
	margin: 0;
	background: linear-gradient(to right, var(--root-base-color), var(--root-secondary-phase-color));
	background-color: #0f172a;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
	--white-font-color: #fff;
	--titles-font-color: #e6e1e1;
	--text-color: #8fb5bf;
	--light-blue-color: #60b5ca;
	--dark-blue-color: #1671bc;
	--list-item-bg-color: #193652;
	--sub-titles-font-weight: 600;
	--titles-font-weight: 700;
	--titles-font-size: 2.5rem;
	--sub-titles-font-size: 1.1rem;
	--navbar-item-font-size: 0.8rem;
	--text-font-size: 1rem;
	--icon-font-size: 0.8rem;
	--icon-font-size-mobile: 0.7rem;
	--layout-left-margin-mobile: 0.9rem;
	--titles-font-size-mobile: 2rem;
	--sub-titles-font-size-mobile: 1rem;
	--root-base-color: #012233;
	--root-secondary-phase-color: #033542;
}
/* 
Teal Blue Shades:
--root-base-color: #0a172d;
--root-secondary-phase-color: #1c2436;


--root-base-color: #150a2d;
	--root-secondary-phase-color: #2b223b;
	

--light-blue-color: #5ce4d4;
 */
