/* Navbar.css */

.navbar {
	padding: 0;
	margin: 5rem 0;
}

.navbar ul {
	display: flex;
	flex-direction: column;
	justify-content: left;
	list-style: none;
	padding: 0;
	margin: 0;
	gap: 1.5rem;
}

.navbar li {
	cursor: pointer;
	font-size: var(--navbar-item-font-size);
	color: var(--text-color);
	transition: color 0.3s ease, transform 0.3s ease; /* Add transition for transform */
	font-weight: var(--sub-titles-font-weight);
	transform-origin: center; /* Set the transform origin */
}

.navbar li:hover {
	color: var(--titles-font-color);
	transform: scale(1.1); /* Enlarge the text */
}

.navbar .active {
	color: var(--titles-font-color);
	transform: scale(1.1); /* Apply the same effect to the active item */
}
@media (max-width: 1024px) {
	.navbar {
		display: none;
	}
}
