.loading-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
}

.loading-spinner {
	border: 8px solid var(--titles-font-color);
	border-top: 8px solid var(--light-blue-color);
	border-radius: 50%;
	width: 50px;
	height: 50px;
	animation: spin 2s linear infinite;
}

.loading-text {
	margin-top: 16px;
	font-size: 18px;
	color: var(--text-color);
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
